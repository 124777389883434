<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :loading="loading"
      class="mx-auto my-3"
      max-width="250"
      width="250"
      style="overflow: hidden;"
    >
      <div
        v-if="product.descuento"
        style="position: absolute; top: 0; right: 0; z-index: 2; background: white;"
      >
        <v-icon color="green">mdi-brightness-percent</v-icon>
      </div>
      <icons-dialog :prod="product"></icons-dialog>
      <!-- esto está comentado es por si se vuelve a poner el icono de detalles -->
      <!-- <v-hover> -->
      <!--   <v-img -->
      <!--     slot-scope="{ hover }" -->
      <!--     v-if="hover" -->
      <!--     class="img-prod-transform" -->
      <!--     height="150" -->
      <!--     :src="product.producto.imagenes[0].imagen" -->
      <!--   ></v-img> -->
      <!--   <v-img -->
      <!--     v-else -->
      <!--     height="150" -->
      <!--     :src="product.producto.imagenes[0].imagen" -->
      <!--   ></v-img> -->
      <!-- </v-hover> -->
    
      <v-card-title
        class="d-inline-block font-weight-bold subtitle-1 text-truncate"
        style="max-width: 250px;line-height:1"
      >
        {{ product.producto.nombre_producto }}
      </v-card-title>
     <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >
          <!--- <v-rating
            :value="product.producto.calificacion"
            color="primary"
            background-color="secondary"
            dense
            half-increments
            readonly
            size="16"
          ></v-rating>
          <div class="grey--text my-1">
            ({{ product.producto.calificacion }}) 
          </div>-->
        </v-row>
         <div class="my-1 subtitle-1"
         style="color: #FF4F00;font-weight: bold;line-height:1;
         "
         >
           <span style="color:black;font-weight: normal;font-size: small;">Presentación:</span>  {{ product.producto.opcion_producto[0].nombre_opcion}} 
          </div> 
         <div class="my-1 subtitle-1"
         style="font-weight:bold; color:black;line-height:1;"
         >
          <span style="color:black;font-weight: normal; font-size: small;">Unidad:</span> {{product.producto.unidad_medida.dato}} 
        </div> 
        <div class="my-1 subtitle-1" style="color:black;line-height:1;">
         <span style="color:black;font-size: small;">Marca:</span> {{ product.producto.marca.dato}} 
          </div>
        <div class="my-1 subtitle-1" style="color:black; line-height:1;">
         ${{ product.producto.precio }} / {{ product.producto.unidad_medida.dato }}
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['usuario/loggedIn'] && $store.getters['usuario/esCliente']"
          :color="product.inWishlist ? 'secondary' : ''"
          icon
          :title="product.inWishlist ? 'Quitar de la lista de deseos' : 'Agregar a lista de deseos'"
          @click="emitAction(product.inWishlist, product)"
        >
          <v-icon>{{ product.inWishlist ? 'mdi-playlist-remove' : 'mdi-playlist-plus' }}</v-icon>
        </v-btn>
        <v-btn
          v-if="showingCart"
          color="primary"
          icon
          title="Agregar al carrito"
          @click="$emit('addToCart', product)"
        >
          <v-icon>mdi-cart-plus</v-icon>
        </v-btn>
        <!-- <icons-dialog :prod="product"></icons-dialog> -->
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    props: {
      product: {
        type: Object,
        required: true
      },
    },
    components: {
      iconsDialog: () => import('@/components/utils/IconsDialog.vue')
    },
    computed: {
      ...mapGetters('usuario', ['showingCart'])
    },
    data () {
      return {
        loading: false
      }
    },
    methods: {
      emitAction(inWishlist, product) {
        // console.log('Hizo click en el boton para agregar a lista de deseos');
        // console.log('inWishlist', inWishlist, 'product', product);
        if (inWishlist) {
          this.$emit('removeOfWishlist', product)
        } else {
          this.$emit('addToWishlist', product)
        }
      }
    }
  }
</script>

<style scoped>
.v-card.on-hover {
  background-color: rgba(#fff, 0.8)
}
</style>

